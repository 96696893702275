<template>
  <b-modal
    visible
    scrollable
    centered
    size="md"
    :title="'Деперсонализация пациента'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы уверены, что хотите деперсонализировать пациента?
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="onClose"
            >
              Отменить
            </b-button>

            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="onClickDelete"
            >
              Деперсонализировать
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PatientDepersonalizateModal',
  methods: {
    onClickDelete() {
      this.$emit('delete');
      this.onClose();
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
